<!--
 * @FilePath: /daren-html-source/src/components/ShowRemark/index.vue
 * @Description: 展示提现失败原因
-->
<template>
  <el-dialog
    :visible.sync="isShow"
    custom-class="titleDialog1"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left">
          <span>失败原因</span>
        </div>
        <fm-button
          @click="handleClose"
          type="info"
          size="mini"
          text
          icon="icon-model-close"
        ></fm-button>
      </div>
      <div class="d-content">
        <div class="title">{{ remarkTit }}</div>
      </div>
      <div class="d-footer">
        <fm-button @click="handleClose" type="primary" size="small"
          >确 定</fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    remarkTit: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },

  methods: {
    handleClose() {
      this.$parent.showRemarkDialog = false;
    },

    confirm() {},
  },
};
</script>

<style lang="scss">
.el-dialog {
  border-radius: 16px;
  color: #fff;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    // box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 8px 10px -5px rgba(0, 0, 0, 0.08);
    padding: 20px 24px 24px 24px;
    border-radius: 16px;
    color: #2c2c2c;
  }
  .d-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
    .fm-button {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
  .d-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      &:last-child {
        margin-left: 12px;
      }
    }
  }
}
.titleDialog1 {
  width: 480px;
  // height: 452px;
  .d-header {
    .left {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }
  .d-content {
    margin: 32px 0 24px 0;
    .title {
      font-size: 16px; //设计图有问题18 ——> 16
      line-height: 24px;
      text-align: center;
      color: #8a8b92;
    }
    .txt {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      span {
        line-height: 22px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.item {
  display: flex;
  align-items: flex-start;
  height: 68px;
  font-weight: 400;
  font-size: 14px;
  color: #2c2c2c;
  .left {
    height: 40px;
    line-height: 40px;
    width: 86px;
    text-align: right;
    flex-shrink: 0;
  }
  .right {
    margin-left: 20px;
    // width: 320px;

    .sendBox {
      display: flex;
      height: 40px;
      align-items: center;
      .btnSend {
        font-weight: 500;
        font-size: 12px;
        color: #7E72F6;
        line-height: 20px;
        margin-left: 16px;
        cursor: pointer;
      }
    }
    .intr {
      height: 20px;
      font-weight: 400;
      font-size: 12px;
      color: #f53f3f;
      line-height: 20px;
    }
    .phoneBox {
      height: 40px;
      line-height: 40px;
    }
  }
}
.pcodeBox {
  position: relative;
  display: flex;
  .pcode {
    position: absolute;
    top: 50%;
    left: 12px;
    font-weight: 400;
    font-size: 14px;
    z-index: 100;
    transform: translateY(-50%);
  }
  ::v-deep .el-input .el-input__inner {
    padding-left: 54px;
  }
}
</style>
