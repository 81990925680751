<!--
 * @FilePath: /daren-html-source/src/components/TablePublic/index.vue
 * @Description:  表格组件
 
-->
<template>
  <div class="tableMemNum">
    <div class="pub-tit1" style="margin: 40px 0 20px 0">{{ tableTitle }}</div>
    <div class="tableHeader">
      <div
        v-for="(item, index) in tableHeader"
        :key="index"
        :class="`table${index}`"
      >
        {{ item }}
      </div>
    </div>
    <div class="tableContent">
      <div class="empty" v-if="total == 0">
        <img class="emptyImg" :src="$utils.getPng('icon_new_empty')" alt="" />
        <div class="emptyText">{{ $t('TablePublic.NoRecord') }}</div>
      </div>
      <div v-else v-loading="tableLoading">
        <div class="item" v-for="item in tableList" :key="item">
          <div class="table0">{{ item.nickname }}</div>
          <div class="table1" v-if="item.in_time">{{ item.in_time }}</div>
          <div class="table1" v-if="item.invite_type">
            {{
              item.invite_type === "1"
                ? $t('TablePublic.QRCode') 
                : item.invite_type === "2"
                ? $t('TablePublic.InvitationCode') 
                : $t('TablePublic.InvitationLink') 
            }}
          </div>
          <div class="table2" v-if="item.backmoney">{{ item.backmoney }}</div>
          <div class="table2" v-if="item.invited_back">
            {{ item.invited_back }}
          </div>
        </div>

        <div class="pagation">
          <PaginationCom
            :total="total"
            @changepage="changepage"
          ></PaginationCom>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaginationCom from "@/components/pagination/index.vue";

export default {
  components: {
    PaginationCom,
  },
  props: {
    tableList: {
      type: Array,
      default: () => [],
    },
    tableTitle: {
      type: String,
      default: () => "开通会员人数（35）",
    },
    tableHeader: {
      type: Array,
      default: () => ["用户昵称", "开通时间", "返利金额"],
    },
    total: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      tableLoading: false,
    };
  },
  methods: {
    changepage(page) {
      this.$parent?.getinviteNumPeopleList(page);
      this.$store.commit("toTop");
    },
  },
};
</script>

<style lang="scss" scoped>
.tableMemNum {
  .table0 {
    width: 424px;
    @include multiline(1);
  }
  .table1 {
    width: 206px;
    margin: 0 24px;
  }
  .table2 {
    width: 206px;
    text-align: right;
    margin-left: auto;
    margin-right: 0;
  }
  .tableHeader {
    width: 100%;
    height: 52px;
    background: #f9f9f9;
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px;
    color: #8a8b92;
    display: flex;
    align-items: center;
    padding: 0 24px;
  }
  .tableContent {
    display: flex;
    flex-direction: column;
    .empty {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      margin-top: 104px;
      .emptyImg {
        width: 100px;
        height: 100px;
      }
      .emptyText {
        margin-top: 8px;
        font-size: 16px;
        color: #8a8b92;
      }
    }
    .item {
      font-size: 14px;
      color: #2c2c2c;
      display: flex;
      align-items: center;
      padding: 0 24px;
      height: 56px;
      box-shadow: inset 0px -1px 0px 0px #f6f6f6;
    }
    .pagation {
      margin-left: auto;
      margin-right: 0;
      margin-top: 94px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
