<!--
 * @FilePath: /daren-html-source/src/components/NavTop/index.vue
 * @Description: 顶部菜单栏
-->
<template>
  <div class="navTop">
    <div class="center">
      <!-- <img
        class="logo pointer"
        @click="tohome"
        :src="$utils.getPng('logoNew')"
        alt=""
      /> -->
      <div class="logoBox pointer" @click="tohome">
        <svg-btn icon-class="logo" class="logoOne"/>
        <svg-btn icon-class="logo-text" class="logoText" />
      </div>
      <div class="rightBox">
        <div class="langBox">
          <div class="lang">
            <span class="langTest">{{
              $i18n.locale == 'zhhk' ? '繁體中文' : $i18n.locale == 'en'? 'English':'简体中文'
            }}</span>
            <svg-btn class="iconBottom" icon-class="icon-bottom"></svg-btn>
          </div>
          <div class="langMoreBox"></div>
          <div class="langMore">
            <div class="one" @click="checkLang('en')">
              <div class="ontit">English</div>
            </div>
            <div class="one one2" @click="checkLang('zh')">
              <div class="ontit">简体中文</div>
            </div>
            <div class="one one2" @click="checkLang('zhhk')">
              <div class="ontit">繁體中文</div>
            </div>
          </div>
        </div>
        <div class="loginInfo" v-if="fmCookieName">
          <div class="userGen">
            <img :src="$store.state.userInfo.logo" alt="" />
            <span>{{ $store.state.userInfo.nickname }}</span>
          </div>
          <div class="general" @click="isShowuserNews = true">
            <el-badge
              :max="99"
              :value="$store.state.userMsgNub"
              :hidden="$store.state.userMsgNub == 0"
            >
              <svg-btn class="logo1" icon-class="icon-general"></svg-btn>
            </el-badge>
          </div>
          <div class="user2"></div>
          <div class="loginOut">
            <div class="one pointer" @click="loginoutClick(1)">
              <img class="imggr" :src="$utils.getPng('icon_22')" alt="" />
              <div class="ontit">{{ $t('nav.Account') }}</div>
            </div>
            <div class="one one2 pointer" @click="loginoutClick(2)">
              <img class="imggr" :src="$utils.getPng('icon_21')" alt="" />
              <div class="ontit">{{ $t('nav.SignOut') }}</div>
            </div>
          </div>
        </div>
        <div class="loginbtn" v-else>
          <fm-button
            type="primary"
            round
            size="medium"
            style="margin: 0 16px 0 0"
            @click="toLogin()"
            >{{ $t('nav.Login') }}</fm-button
          >
          <fm-button
            type="info"
            round
            size="medium"
            @click="toLogin('register')"
            >{{ $t('nav.Sign') }}</fm-button
          >
          <span class="divide"> </span>
        </div>
      </div>
      <userNews
        :isShow="isShowuserNews"
        @handleClose="isShowuserNews = false"
      ></userNews>
    </div>
  </div>
</template>

<script>
import {
  userInfoAPI,
  userMsginfoapi,
  loginOutAPI,
} from '@/utils/apiList/user.js'
import userNews from '@/components/userNews/index.vue'

export default {
  data() {
    return {
      fmCookieName: this.$utils.getCookie(this.$glb.fmCookieName),
      isShowuserNews: false,
    }
  },
  components: {
    userNews,
  },
  created() {
    this.getUser()
    this.userMsginfoapiFn()
  },
  mounted() {
    if (this.$utils.getCookie(this.$glb.fmCookieName)) {
      localStorage.setItem(
        'darenToken',
        this.$utils.getCookie(this.$glb.fmCookieName)
      )
    }
    this.$store.commit('setAttr', {
      name: 'getUserInfo',
      val: this.getUser,
    })
    this.$store.commit('setAttr', {
      name: 'getNewCookie',
      val: this.getNewCookie,
    })
    document.addEventListener('visibilitychange', this.handleVisiable)
  },
  destroyed() {
    document.removeEventListener('visibilitychange', this.handleVisiable)
  },
  methods: {
    checkLang(row) {
      this.$i18n.locale = row
      localStorage.setItem('lang', row)
      if (row == 'en') {
        if (this.$route.fullPath.indexOf('/en-US') == -1) {
          this.$router.push({ path: '/en-US' + this.$route.fullPath.replace('/zh-HK', '') })
        }
      }else if(row == 'zhhk'){
        if (this.$route.fullPath.indexOf('/zh-HK') == -1) {
          this.$router.push({ path: '/zh-HK' + this.$route.fullPath.replace('/en-US', '') })
        }
      } else {
        const newPath = this.$route.fullPath.replace('/en-US', '').replace('/zh-HK', '');
        this.$router.push({ path: newPath == '' ? '/' : newPath })
      }
    },
    getNewCookie() {
      this.fmCookieName = this.$utils.getCookie(this.$glb.fmCookieName)
      console.log('getNewCookie', this.fmCookieName)
    },
    toLogin(item) {
      let path = this.$route.path.toString().slice(1)
      const from = 'daren'
      const fromUrl = this.$glb.darenUrl + `/${path}`
      const encodedFrom = encodeURIComponent(from)
      const encodedFromUrl = encodeURIComponent(fromUrl)
      if (item == 'register') {
        let query = {
          path: 'drive',
          tab: '2',
        }

        // this.$router.push({ path: "/login", query });
        window.location.href =
          this.$glb.fmpUrl +
          `/login?path=drive&tab=2&from=${encodedFrom}&fromUrl=${encodedFromUrl}`
      } else {
        // this.$router.push({ path: "/login", query: { path: path } });
        window.location.href =
          this.$glb.fmpUrl +
          `/login?from=${encodedFrom}&fromUrl=${encodedFromUrl}`
      }
      return
    },
    getUser() {
      userInfoAPI()
        .then(res => {
          if (res.status == 1) {
            this.$store.commit('setAttr', {
              name: 'userInfo',
              val: res.data,
            })
            localStorage.setItem('isDaren', res.data.is_rate_flow)
          } else {
            this.$toast(res.msg, 3)
          }
        })
        .catch(err => {})
    },
    userMsginfoapiFn() {
      userMsginfoapi()
        .then(res => {
          this.$store.commit('setAttr', {
            name: 'userMsgNub',
            val: +res.data.count || 0,
          })
        })
        .catch(err => {})
    },
    loginoutClick(index) {
      index == 1 ? this.$router.push({ path: '/account' }) : this.loginOut()
    },
    loginOut() {
      loginOutAPI()
        .then(res => {
          this.$utils.clearCookie(this.$glb.fmCookieName)
          this.$store.commit('setAttr', {
            name: 'userInfo',
            val: {},
          })
          this.$toast('请先登录', 3)
          localStorage.removeItem('isDaren')
          this.fmCookieName = this.$utils.getCookie(this.$glb.fmCookieName)
          this.$router.push({ path: '/homepage' })
        })
        .catch(err => {})
    },
    tohome() {
      this.$router.push({ path: '/drive' })
    },
    handleVisiable(e) {
      switch (e.target.visibilityState) {
        case 'prerender':
          // console.log("网页预渲染，内容不可见");
          break
        case 'hidden':
          // console.log("内容不可见，处理后台、最小化、锁屏状态");
          break
        case 'visible':
          // console.log("visible");
          this.fmCookieName = this.$utils.getCookie(this.$glb.fmCookieName)
          if (
            this.$utils.getCookie(this.$glb.fmCookieName) &&
            this.$utils.getCookie(this.$glb.fmCookieName) !=
              localStorage.getItem('darenToken')
          ) {
            userInfoAPI()
              .then(res => {
                if (res.status == 1) {
                  this.$store.commit('setAttr', {
                    name: 'userInfo',
                    val: res.data,
                  })
                  localStorage.setItem('isDaren', res.data.is_rate_flow)
                  if (res.data.is_rate_flow == 1) {
                    this.$router.push({ path: '/drive' })
                  } else {
                    this.$router.push({ path: '/homepage' })
                    sessionStorage.setItem('isFirst', 1)
                  }
                } else {
                  this.$toast(res.msg, 3)
                }
              })
              .catch(err => {})
          }

          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
