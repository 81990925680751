import { render, staticRenderFns } from "./offIncomeData.vue?vue&type=template&id=258f97ef&scoped=true"
import script from "./offIncomeData.vue?vue&type=script&lang=js"
export * from "./offIncomeData.vue?vue&type=script&lang=js"
import style0 from "./offIncomeData.vue?vue&type=style&index=0&id=258f97ef&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "258f97ef",
  null
  
)

export default component.exports