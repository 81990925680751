<!--
 * @FilePath: /daren-html-source/src/components/Dialog/EquipmentDialog.vue
 * @Description: 设备管理弹窗
-->

<template>
  <div class="EquipmentDialog" id="equipmentDia">
    <el-dialog
      :visible.sync="equipmentFlag0"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <div class="diaBox">
        <div class="dialogTop">
          <div class="left">
            <img class="icon" :src="$utils.getPng('icon_i')" alt="" srcset="" />
            <div class="tit1">安全提示</div>
          </div>
        </div>
        <div class="dialogCenter">
          <div class="tit">
            当前设备已被下线，如非本人操作请及时修改密码，避免所存储的数据丢失。
          </div>
          <div class="bottom">
            <fm-button
              style="width: 110px"
              class="btn"
              type="primary"
              size="medium"
              @click="toLoiginOut()"
              >重新登录</fm-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { loginOutAPI } from "@/utils/apiList/user.js";
import router from "@/router/index";

export default {
  data() {
    return {
      equipmentFlag0: false,
      dataArr: {},
    };
  },

  methods: {
    toLoiginOut() {
      loginOutAPI()
        .then((res) => {
          localStorage.removeItem("platfro");
          let path = 'homepage';
          const from = "daren";
          const fromUrl = this.$glb.darenUrl + `/${path}`;
          const encodedFrom = encodeURIComponent(from);
          const encodedFromUrl = encodeURIComponent(fromUrl);

          this.equipmentFlag0 = false;
          this.$utils.clearCookie(this.$glb.fmCookieName);
          window.location.href =
            this.$glb.fmpUrl +
            `/login?from=${encodedFrom}&fromUrl=${encodedFromUrl}`;
        })
        .catch((err) => {});
    },

    handleOpen() {
      this.equipmentFlag0 = true;
    },
    handleClose() {
      this.equipmentFlag0 = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 480px;
  min-height: 208px;
  box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05),
    0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 8px 10px -5px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  .el-dialog__body {
    padding: 0;
  }
}

.diaBox {
  .dialogTop {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 20px 24px 0 24px;
    .left {
      display: flex;
      align-items: center;
    }
    .icon {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 8px;
    }
    .tit1 {
      height: 24px;
      font-size: 16px;
      font-weight: 500;

      color: #2c2c2c;
      line-height: 24px;
    }
  }
  .dialogCenter {
    font-size: 14px;
    font-weight: 400;

    color: #2c2c2c;
    font-size: 14px;
    padding: 32px 24px 24px 24px;
    .titBig {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
    }

    .tit1 {
      text-align: center;
      font-size: 14px;
      font-weight: 400;

      color: #8a8b92;
    }
    .bottom {
      width: 100%;
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn1 {
        width: 110px;
        height: 36px;
        background: rgba(246, 121, 66, 0.1);
        border-radius: 18px;
        color: #7E72F6;
        margin-right: 8px;
        &:hover {
          color: #fff;
          background-color: #7E72F6;
          border-color: #7E72F6;
        }
        &:nth-child(2) {
          margin-right: 0;
          margin-left: 8px;
        }
      }
    }
    .bottom1 {
      display: flex;
      .btn2 {
        margin-right: 0;
      }
    }
  }
}
</style>
