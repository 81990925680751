<!--
 * @FilePath: /daren-html-source/src/views/Account/incomeReflect.vue
 * @Description: 收益提现
-->
<template>
  <div class="memberRebate min-height">
    <div class="incomeReflect">
      <div class="tabTop">
        <div
          class="item pointer"
          :class="tab_act == item.id ? 'item_act' : ''"
          @click="tab_act = item.id"
          v-for="item in tabTop"
          :key="item.id"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="tabCenter">
        <Tab0 v-if="tab_act == 0" />
        <Tab1 v-if="tab_act == 1" />
        <Tab2 v-if="tab_act == 2" />
      </div>
    </div>
  </div>
</template>

<script>
import Tab0 from "./components/tab0.vue";
import Tab1 from "./components/tab1.vue";
import Tab2 from "./components/tab2.vue";
export default {
  components: {
    Tab0,
    Tab1,
    Tab2,
  },
  data() {
    return {
      tabTop: [
        { id: 0, name: this.$t('IncomeReflect.BalanceWithdrawal') },
        { id: 1, name: this.$t('IncomeReflect.WithdrawalRecord') },
        { id: 2, name: this.$t('IncomeReflect.PayeeManagement') },
      ],
      tab_act: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.incomeReflect {
  .tabTop {
    width: 932px;
    height: 72px;
    box-shadow: inset 0px -1px 0px 0px #f6f6f6;
    display: flex;
    font-weight: 400;
    font-size: 18px;
    color: #8a8b92;
    margin-top: -32px;
    .item {
      height: 72px;
      line-height: 72px;
      border-bottom: 2px solid transparent;
      margin-right: 40px;
    }
    .item_act {
      border-bottom: 2px solid #7E72F6;
      color: #2c2c2c;
      font-weight: 500;
    }
  }
}

.memberRebate {
  background: #f7f7f7;
  .incomeReflect {
    background: #ffffff;
    border-radius: 16px 16px 0px 0px;
    padding: 32px 24px;
    min-height: calc(100vh - 50px - 20px - 30px);
  }
}
</style>
