<template>
  <div class="invite">
    <div class="header">
      <!-- <img :src="$utils.getPng('invite-logo')" height="28" alt=""> -->
        <div style="display: flex;height: 28px;margin-left: 29px">
          <svg-btn icon-class="logo" style="height: 28px;width: 28px;margin-right: 11px" />
          <svg-btn icon-class="logo-text" style="height: 28px;width: 185px;" />
        </div>
        <fm-button
          type="info"
          round
          size="medium"
          @click="open"
          >注 册</fm-button>
    </div>
    <div class="container" style="padding-top: 100px;">
      <img :src="$utils.getPng('invite-title')" height="50" alt="">
      <div style="margin-top: 34px;font-size: 18px;color: rgba(44,44,44,0.9);line-height: 28px;">
        <span>上线十年的流量变现平台</span>
        <span style="color: #7E72F6;margin: 0 20px;">“{{name}}”</span>
        <span>邀请你加入CloudMaster</span>
      </div>
      <div class="signup button" style="margin: 52px auto 88px;" @click="open">
        <span>立即注册</span>
        <svg-btn icon-class="icon-arrow-right" style="margin-left: 4px;width: 14px;height: 14px;"/>
      </div>
      <div style="display: flex;justify-content: space-between; position: relative;width: 959px;margin: 0 auto;">
        <div class="card">
          <img :src="$utils.getPng('home-icon-1')" width="52" alt="">
          <p>注册FEEMOO账号</p>
          <span>超简单极速注册</span>
        </div>
        <div class="card">
          <img :src="$utils.getPng('home-icon-2')" width="52" alt="">
          <p>开通CloudMaster</p>
          <span>达人专属标识/权益</span>
        </div>
        <div class="card">
          <img :src="$utils.getPng('home-icon-3')" width="52" alt="">
          <p>分享文件赚收益</p>
          <span>他人下载/转存即获得收益</span>
        </div>
        <!-- <img :src="$utils.getPng('guide-1')" width="80" alt="" style="position: absolute;bottom: 0;left: 272px;">
        <img :src="$utils.getPng('guide-2')" width="80" alt="" style="position: absolute;bottom: 0;right: 272px;"> -->
        <svg-btn icon-class="guide-1" style="height: 104px;width: 80px;position: absolute;bottom: 0;left: 272px;" />
        <svg-btn icon-class="guide-2" style="height: 104px;width: 80px;position: absolute;bottom: 0;right: 272px;" />
      </div>
    </div>
    <div class="container gray" style="height: 866px;padding-top: 88px;">
      <h1 style="margin-bottom: 14px;">平台优势</h1>
      <p class="subtitle">多种模式灵活玩法多样</p>
      <el-tabs v-model="activeName" style="margin-top: 40px;">
        <el-tab-pane label="FEEMOO返利" name="1">
          <div class="pane-box">
            <img :src="$utils.getPng('invite-card-1')" height="384" alt="">
            <div style="text-align: left;padding: 20px 0;margin-left: 40px;">
              <h2>FEEMOO返利</h2>
              <p>FEEMOO是分享型网盘，成为达人后上传文件，生成分享链接供他人下载。</p>
              <p>可选择<b>下载量返利模式</b>，区分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。</p>
              <p>或选择<b>会员返利模式</b>，不按下载次数计费，只根据下载用户开通会员进行按比例分成，文件不过期，页面更简洁。</p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="优惠码返利" name="2">
          <div class="pane-box">
            <img :src="$utils.getPng('invite-card-2')" height="384" alt="">
            <div style="text-align: left;padding: 20px 0;margin-left: 40px;">
              <h2>优惠码返利</h2>
              <p>使用专属您的邀请码邀请用户开通FEEMOO会员，您可获得返利收益</p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="下线返利" name="3">
          <div class="pane-box">
            <img :src="$utils.getPng('invite-card-3')" height="384" alt="">
            <div style="text-align: left;padding: 20px 0;margin-left: 40px;">
              <h2>下线返利</h2>
              <p>好友通过邀请链接注册FEEMOO账号后申请成为CloudMaster，您可获得好友收益的分成返利。</p>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="container" style="">
      <h1 style="margin-bottom: 14px;">真实收益</h1>
      <p class="subtitle">随机展示上周部分收益提现记录  真实提现看得到</p>
      <div style="margin: 48px auto 0;width: 1220px;display: flex;flex-wrap: wrap;">
        <div v-for="(item, index) in income" class="item" :key="index">
          <img :src="item.src" width="36" height="36" alt="" style="margin-right: 12px;margin-top: 8px;">
          <div>
            <p>{{item.name}}</p>
            <p style="color: #8A8B92;font-weight: 400;margin-top: 2px;">提现{{item.value}}元（累计赚{{item.total}}元）</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container gray" style="padding-bottom: 82px;">
      <h1 style="margin-bottom: 14px;">真实用户反馈</h1>
      <p class="subtitle">上线十年流量变现平台，真实反馈</p>
      <div class="comment-box">
        <transition-group name="list" tag="ul" mode="out-in">
          <li class="comment" v-for="item in list" :key="item.id">
            <p>{{ item.comment }}</p>
            <div style="display: flex;align-items: center;">
              <img :src="item.src" width="36" height="36" alt="" style="margin-right: 12px;">
              <span>{{item.name}}</span>
            </div>
          </li>
        </transition-group>
      </div>
    </div>
    <div class="gray">
      <div style="background-color: #fff;display: flex;align-items: center;justify-content: center;padding: 157px 0;border-radius: 100px 100px 0px 0px;">
        <h1>享受分享的乐趣，轻松赚取属于你的变现收益</h1>
        <div class="signup button" style="margin-left: 40px;" @click="open">
          <span>立即注册</span>
          <svg-btn icon-class="icon-arrow-right" style="margin-left: 4px;width: 14px;height: 14px;"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import name from '../OfflineRebate/name';
import comment from './comment';
import {
  getInviteInfoAPI
} from "@/utils/apiList/offlineRebate";

export default {
  data() {
    return {
      activeName: '1',
      income: [],
      list: [],
      carousel: null,
      query: {},
      name: ''
    }
  },
  created() {
    this.query = this.$route.query
    if (
      navigator.userAgent.match(/Mobi/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i)
    ) location.href = `${this.$glb.H5Url}/invite/#/?type=${this.query.type}&code=${this.query.code}`
    getInviteInfoAPI({invite_code: this.query.code}).then(res => {
      this.name = res.data.name
    })
    for(let i=0;i<12;i++) {
      const value = parseInt(Math.random() * (89999 - 14899) + 14899)
      this.income.push({
        name: this.getName(),
        src: this.$utils.getPng(`avatar-${Math.ceil(Math.random()*9)}`),
        value: value/100,
        total: parseInt(Math.random() * (999999 - Math.ceil(value*1.5)) + Math.ceil(value*1.5))/100
      })
    }
    for(let item of comment) {
      this.list.push({
        id: Math.random(),
        comment: item,
        src: this.$utils.getPng(`avatar-${Math.ceil(Math.random()*9)}`),
        name: this.getName()
      })
    }
  },
  mounted() {
    this.start()
    document.addEventListener("visibilitychange", () => {
      if(document.visibilityState == "visible") {
        this.start()
      } else {
        clearInterval(this.carousel)
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.carousel)
  },
  methods: {
    getName() {
      const nickname = name[Math.floor(Math.random()*name.length)]
      return nickname.at(1) + ''.padEnd(nickname.length-2>6?6:nickname.length-2, '*') + nickname.at(-1)
    },
    start() {
      this.list.push({...this.list[0], id: Math.random()})
      this.list.shift()
      this.carousel = setInterval(() => {
        this.list.push({...this.list[0], id: Math.random()})
        this.list.shift()
      }, 10000)
    },
    open() {
      const fromUrl = process.env.VUE_APP_DAREN_URL + '/drive'
      location.href = `${process.env.VUE_APP_FM_URL}/login?path=drive&tab=2&from=daren&fromUrl=${encodeURIComponent(fromUrl)}&invitecode=${this.query.code}&invitetype=${this.query.type}`
    }
  }
}

</script>

<style lang="scss" scoped>
.invite {
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;
  color: #2C2C2C;
  --content-width: 1200px;

  h1 {
    font-size: 36px;
    line-height: 44px;
    font-weight: 500;
  }
  .subtitle {
    color: #8A8B92;
    font-size: 16px;
    line-height: 24px;
  }
  .header {
    width: var(--content-width);
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }
  .container {
    min-width: var(--content-width);
    text-align: center;
    padding: 152px 0;
    :deep(.el-tabs__nav-wrap::after) {
      height: 1px;
      background-color: #E5E5E5;
    }
    :deep(.el-tabs__nav-scroll) {
      display: flex;
      justify-content: center;
    }
    :deep(.el-tabs__item) {
      color: #2C2C2C;
      line-height: 24px;
      padding: 0 60px;
    }
    :deep(.el-tabs__item.is-active) {
      color: #7E72F6;
    }
    
    :deep(.el-tabs__item:focus.is-active.is-focus:not(:active)) {
      box-shadow: 0 0;
      border-radius: 0;
    }
    :deep(.el-tabs__active-bar) {
      background: none;
      display: flex;
      justify-content: center;
      height: 3px;
    }
    :deep(.el-tabs__active-bar::before) {
      content: " ";
      width: 68px;
      flex: none;
      height: 3px;
      background-color: #7E72F6;
      border-radius: 2px 2px 0px 0px;
    }
    :deep(.el-tabs__item:hover) {
      color: #7E72F6;
    }
    
    .card {
      background: #F9F9F9;
      border-radius: 24px;
      width: 288px;
      height: 196px;
      text-align: left;
      padding: 32px;
      line-height: 0;
      p {
        margin: 24px 0 6px;
        font-size: 18px;
        line-height: 26px;
        font-weight: 500;
      }
      span {
        margin-top: 6px;
        color: #8A8B92;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }
    .pane-box {
      width: 1128px;
      margin: 72px auto 0;
      display: flex;
      h2 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        margin-bottom: 24px;
      }
      p {
        line-height: 24px;
        font-weight: 400;
        margin: 12px 0;
        opacity: 0.9;
      }
    }
    .item {
      margin: 24px 10px 0;
      width: 285px;
      height: 110px;
      border-radius: 16px;
      line-height: 24px;
      font-size: 16px;
      padding: 18px 20px;
      display: flex;
      text-align: left;
      border: 1px solid #E5E5E5;
    }
    .comment-box {
      width: 1312px;
      margin: 2px auto 0;
      height: 308px;
      overflow: hidden;
      position: relative;
      > ul {
        height: 100%;
        display: flex;
        align-items: center;
        transform: translateX(-30%);
      }
      .comment {
        flex: none;
        width: 296px;
        height: 168px;
        background: #FFFFFF;
        box-shadow: 0px 8px 32px 0px rgba(0,0,0,0.06);
        border-radius: 16px;
        border: 1px solid #F1F1F1;
        display: inline-block;
        margin: 0 16px;
        padding: 20px 24px 24px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: all 10s linear;
        > p {
          font-size: 16px;
          line-height: 24px;
          color: #8A8B92;
        }
      }

      /* 确保将离开的元素从布局流中删除
        以便能够正确地计算移动的动画。 */
      .list-leave-active {
        position: absolute;
      }
    }
    .comment-box::before {
      content: " ";
      position: absolute;
      z-index: 2;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient( 90deg, #F9F9F9 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 70%, #F9F9F9 100%);
    }
  }
  .signup {
    width: 180px;
    height: 54px;
    color: #fff;
    border-radius: 28px;
    font-weight: 500;
    line-height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
  }
  .button {
    color: #fff;
    user-select:none;
    transition: background-color .15s;
    background-color: #7E72F6;
  }
  .button:hover {
    background-color: #A39BF6;
  }
  .button:active {
    background-color: #3213D8;
  }
  .gray {
    background: #F9F9F9;
  }
}
</style>
