const axios = require('axios')
var urlObj = {}

async function initConfig() {
  try {
    const response = await axios.get(
      process.env.VUE_APP_ADDRESS_URL + '/Public/web/js/address/address.js' + `?index=${Math.random()}`
    )
    // 查找第一个 '{' 的位置，用于确定有效JSON数据的起始位置
    const startIndex = response.data.indexOf('{')
    // 查找最后一个 '}' 的位置，并加1，用于确定有效JSON数据的结束位置
    const endIndex = response.data.lastIndexOf('}') + 1
    // 从响应数据中截取从起始位置到结束位置的字符串，得到纯JSON格式的数据
    const pureJsonData = response.data.substring(startIndex, endIndex)
    const parsedData = JSON.parse(pureJsonData)
    const { daren } = parsedData
    urlObj = daren
    // 遍历urlObj对象，去除字符串属性值两边的空格
    for (const key in urlObj) {
      if (typeof urlObj[key] === 'string') {
        urlObj[key] = urlObj[key].trim()
      }
    }
  } catch (error) {
    //请求报错
  }
}

// 在这里使用await等待initConfig执行完成
await initConfig()

var getApiUrl = process.env.VUE_APP_API_URL
var cdnPic = process.env.VUE_APP_CDN_URL_PIC
var fmpUrl = urlObj.fmpUrl || process.env.VUE_APP_FM_URL
var darenUrl = urlObj.darenUrl || process.env.VUE_APP_DAREN_URL
var upJmdyhUrl = urlObj.upJmdyhUrl || process.env.VUE_APP_UPJMDYH_URL
var kJmdyhUrl = urlObj.kJmdyhUrl || process.env.VUE_APP_JMDYH
var H5Url = urlObj.H5Url || process.env.VUE_APP_H5_URL
var fmCookieName = 'feemoo_daren_token'

export default {
  getApiUrl,
  cdnPic,
  fmpUrl,
  darenUrl,
  fmCookieName,
  upJmdyhUrl,
  kJmdyhUrl,
  H5Url,
}
