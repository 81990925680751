<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    class="tipDialog2"
    :append-to-body="true"
    :modal="false"
    @close="handleClose"
  >
    <div class="dialog-body">
      <div class="news">
        <div class="ce" @click="handleClose"></div>
        <div class="newsCenter">
          <div class="newsTop app-right-box2">
            <div class="changeBox" v-if="newsTypeAct == 0">
              <div
                class="item pointer"
                v-for="item in changeType0"
                :key="item.id"
                :class="item.id == changeAct0 ? 'itemAct' : ''"
                @click="changeActFun(0, item.id)"
              >
                {{ item.name }}
              </div>
              <div
                class="itemAct2"
                :class="['slateX1', 'slateX2', 'slateX3'][changeAct0]"
              ></div>
            </div>
            <div class="rightBox">
              <div class="all pointer" @click="allRead(1)">{{ $t('userNew.ReadAll') }}</div>
              <fm-button
                type="info"
                class="fm-buttona"
                size="medium"
                text
                icon="icon-refresh1"
                @click="allRead(2)"
              ></fm-button>
            </div>
          </div>
          <div
            class="one"
            ref="scrollBox"
            v-infinite-scroll="getuserMsg"
            infinite-scroll-distance="50"
            v-loading="newsDownLoading2"
            @scroll="scrollBoxFun()"
          >
            <div
              class="newsDown app-right-box2"
              :class="
                !newsDownLoading && userMsglist.length == 0 ? 'default' : ''
              "
            >
              <el-skeleton style="width: 100%" :loading="newsDownLoading">
                <!-- 内容 -->
                <template>
                  <div
                    class="itemNotice pointer"
                    v-for="item in userMsglist"
                    :key="item"
                    @click="msgNest(item)"
                  >
                    <el-badge :is-dot="item.isrd == 0">
                      <img class="avater" :src="item.ext_icon" alt="" />
                    </el-badge>
                    <div class="rightInfo">
                      <div class="top">
                        <div class="name">{{ item.label }}</div>
                        <div class="time">{{ item.in_time }}</div>
                      </div>
                      <div class="tit">
                        <span v-if="item.amount">￥{{ item.amount }}</span
                        >{{ item.title }}
                      </div>
                      <!-- 官方活动 -->
                      <div class="box1" v-if="typeof item.child == 'string'">
                        <div
                          class="content"
                          :class="
                            item.img_url == '' && item.jump_url == ''
                              ? 'content2'
                              : ''
                          "
                        >
                          {{ item.child }}
                        </div>
                        <img
                          v-if="item.img_url"
                          class="imga"
                          :src="item.img_url"
                          alt=""
                        />
                        <div class="down" v-if="item.jump_url">
                          <div class="btnLeft">去查看</div>
                          <img
                            class="imgNext"
                            :src="$utils.getPng('icon_next')"
                            alt=""
                          />
                        </div>
                      </div>
                      <!-- 系统通知 -->
                      <div class="box2" v-else>
                        <div
                          class="refuse"
                          v-for="(i, index) in item.child"
                          :key="index"
                        >
                          <span class="refTit">{{ i.name }}：</span>
                          <span
                            class="refTit"
                            :style="{
                              color:
                                $store.state.theme == 'dark' &&
                                i.color == '2C2C2C'
                                  ? ''
                                  : `#${i.color}`,
                            }"
                            >{{ i.value }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="isOver" v-if="userMsglist.length != 0 && isOver">
                    没有更多了
                  </div>
                </template>
                <!-- 骨架 -->
                <template slot="template">
                  <div class="itemNotice" v-for="item in 10" :key="item">
                    <el-skeleton-item class="avater" variant="circle" />
                    <div class="rightInfo">
                      <div class="top">
                        <el-skeleton-item style="width: 20%">
                        </el-skeleton-item>
                        <el-skeleton-item style="width: 10%">
                        </el-skeleton-item>
                      </div>
                      <el-skeleton-item style="margin: 11px 0">
                      </el-skeleton-item>
                      <!-- 官方活动 -->
                      <div class="box1">
                        <el-skeleton-item style="margin: 4px 0">
                        </el-skeleton-item>
                        <el-skeleton-item style="margin: 4px 0">
                        </el-skeleton-item>
                        <el-skeleton-item style="margin: 4px 0; width: 50%">
                        </el-skeleton-item
                        ><br />
                        <el-skeleton-item class="imga" style="margin: 11px 0">
                        </el-skeleton-item>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="userMsglist.length == 0">
                  <div class="newsDown2 app-right-box2">
                    <el-empty
                      :image-size="88"
                      :image="$utils.getPng('icon_new_empty')"
                      :description="$t('userNew.Nonews')"
                    >
                    </el-empty>
                  </div>
                </template>
              </el-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { userMsg, userMsgread } from "@/utils/apiList/user";
export default {
  name: "base",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    recycle_expire_day: {
      type: String,
      default: "",
    },
    recycle_tit: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      newsTypeAct: 0,
      changeType0: [
        { name: this.$t('userNew.All'), id: 0 },
        { name: this.$t('userNew.SystemNotification'), id: 1 },
        { name: this.$t('userNew.OfficialNews'), id: 2 },
      ],
      changeAct0: 0,
      changeType1: [
        { name: this.$t('userNew.All'), id: 0 },
        { name: "评论", id: 1 },
      ],
      changeAct1: 0,
      showTop: false,
      newsDownLoading: true,
      newsDownLoading2: false,
      params: {
        page: 0,
        page_size: 30,
        msg_type: -1,
      },
      isOver: false,
      userMsglist: [],
    };
  },
  watch: {
    isShow(newVal, oldVal) {
      if (newVal) {
        this.resetting();
      }
    },
  },
  mounted() {
    if (this.$utils.getCookie(this.$glb.fmCookieName)) {
      this.getuserMsg();
    }
  },
  methods: {
    resetting() {
      this.params = {
        page: 0,
        page_size: 30,
        msg_type: -1,
      };
      this.changeAct0 = 0;

      this.isOver = false;
      this.getuserMsg();
    },
    allRead(type) {
      if (type == 1) {
        this.userMsglist = this.userMsglist.map((obj) => ({
          ...obj,
          isrd: 1,
        }));
        this.userMsgreadFn(-1, true);
      } else {
        this.newsDownLoading2 = true;
        this.scrollToTop();
        this.params = {
          page: 0,
          page_size: 30,
          msg_type: this.params.msg_type,
        };

        this.isOver = false;
        this.getuserMsg(true);
      }
    },
    msgNest(item) {
      if (item.jump_url) {
        window.open(item.jump_url);
      }
      this.userMsglist = this.userMsglist.map((obj) => {
        if (obj.id == item.id && item.isrd == 0) {
          this.userMsgreadFn(obj.id, false);
          return { ...obj, isrd: 1 };
        }
        return obj;
      });
    },
    userMsgreadFn(id, type) {
      userMsgread({ id, msg_type: this.params.msg_type })
        .then((res) => {
          if (res.status == 1) {
            if (type) {
              this.$toast("已将该列表消息标记为已读", 1);
            }
            this.$store.commit("setAttr", {
              name: "userMsgNub",
              val: +res.data.count,
            });
            // this.newsType[0].num = +res.data.count || 0;
          }
        })
        .catch((err) => {});
    },
    getuserMsg: _.debounce(
      function (type) {
        if (this.isOver && !type) return;
        this.params.page++;
        userMsg(this.params)
          .then((res) => {
            if (res.status == 1) {
              this.newsDownLoading = false;
              this.newsDownLoading2 = false;
              if (res.data.list.length < this.params.page_size) {
                this.isOver = true;
              }
              this.$store.commit("setAttr", {
                name: "userMsgNub",
                val: +res.data.count,
              });
              // this.newsType[0].num = +res.data.count;
              if (this.params.page == 1) {
                this.userMsglist = res.data.list;
              } else {
                this.userMsglist = this.userMsglist.concat(res.data.list);
              }
            } else {
              // this.$toast(res.msg, 2);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      50,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    changeActFun(index, id) {
      if (this.changeAct0 != id) {
        this.isOver = false;
        this.params = {
          page: 0,
          page_size: 30,
          msg_type: id == 0 ? -1 : id,
        };
        this.changeAct0 = id;
        this.getuserMsg();
        this.scrollToTop();
      }

      // this.changeAct1 = id;
    },
    topTypeChange(id) {
      this.newsTypeAct = id;
      this.changeAct0 = 0;
      // this.changeAct1 = 0;
      this.$refs.scrollBox.scrollTop = 0;
    },
    scrollBoxFun() {
      var scrollEl = this.$refs.scrollBox;
      this.showTop = scrollEl.scrollTop > 50;
    },
    scrollToTop() {
      let scrollEl = this.$refs.scrollBox;
      scrollEl.scrollTop = 0;
    },
    handleClose() {
      this.$emit("handleClose");
      setTimeout(() => {
        this.resetting();
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
