<template>
  <div class="all">
    <div class="captcha">
      <!-- :style="{ borderBottomColor: index <= cIndex ? 'red' : '' }" 输入过的border-bottom置红 -->
      <input
        class="input"
        v-for="(c, index) in ct"
        :key="index"
        type="number"
        v-model="ct[index]"
        ref="input"
        :class="err ? 'err' : ''"
        :style="{
          boxShadow:
            index <= cIndex
              ? '0px 6px 30px 5px rgba(0,0,0,0.05), 0px 16px 24px 2px rgba(0,0,0,0.04), 0px 8px 10px -5px rgba(0,0,0,0.08);'
              : '',
        }"
        @input="
          (e) => {
            onInput(e.target.value, index);
          }
        "
        @keydown.delete="
          (e) => {
            onKeydown(e.target.value, index);
          }
        "
        onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
        @focus="onFocus"
        :disabled="loading"
      />
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  props: {
    error: Boolean,
    showDiaFlag: Boolean,
  },
  data() {
    return {
      ct: ["", "", "", "", "", ""],
      loading: false,
      msg: "",
      err: false,
    };
  },
  computed: {
    ctSize() {
      return this.ct.length;
    },
    cIndex() {
      let i = this.ct.findIndex((item) => item === "");
      i = (i + this.ctSize) % this.ctSize;
      return i;
    },
    lastCode() {
      return this.ct[this.ctSize - 1];
    },
  },
  watch: {
    cIndex() {
      this.resetCaret();
    },
    lastCode(val) {
      // this.props.code = this.ct

      if (val) {
        this.$refs.input[this.ctSize - 1].blur();
        this.sendCaptcha();
      }
    },
    ct(val) {
      const arr = JSON.parse(JSON.stringify(val));
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] == null || arr[i] == "") {
          arr.splice(i, 1);
          i--;
        }
      }

      if (arr.length == 6) {
        this.$emit("changeload", false);
      } else {
        this.$emit("changeload", true);
      }
    },
    error(val) {
      this.err = val;
    },
  },
  mounted() {
    // this.resetCaret();
  },
  methods: {
    onInput(val, index) {
      this.msg = "";
      val = val.replace(/\s/g, "");
      if (index == this.ctSize - 1) {
        this.ct[this.ctSize - 1] = val[0]; // 最后一个码，只允许输入一个字符。
      } else if (val.length > 1) {
        let i = index;
        for (i = index; i < this.ctSize && i - index < val.length; i++) {
          this.ct[i] = val[i];
        }
        this.resetCaret();
      }
    },
    // 重置光标位置。
    resetCaret() {
      this.$refs.input[this.ctSize - 1]?.focus();
    },
    onFocus() {
      // 监听 focus 事件，将光标重定位到“第一个空白符的位置”。
      let index = this.ct.findIndex((item) => item === "");
      index = (index + this.ctSize) % this.ctSize;
      // console.log(this.$refs.input);

      this.$refs.input[index].focus();
    },
    onKeydown(val, index) {
      if (val === "") {
        // 删除上一个input里的值，并对其focus。
        if (index > 0) {
          this.ct[index - 1] = "";
          this.$refs.input[index - 1].focus();
        }
      }
    },
    sendCaptcha() {
      this.$nextTick(() => {
        this.$emit("next", this.ct);
      });
    },

    reset() {
      // 重置。一般是验证码错误时触发。
      this.ct = this.ct.map((item) => "");
      this.resetCaret();
    },
  },
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.all {
  position: relative;
}

.captcha {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
}
input {
  margin-right: 20px;
  width: 40px;
  height: 40px;

  border-radius: 6px;
  text-align: center;
  border: none;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  box-sizing: border-box;
  background: #f6f6f6;
  color: #2c2c2c;
}
input:focus-within {
  //聚焦后，改变边框颜色，如果用:focus会导致两层边框
  border: 1px solid #7E72F6;
}
input:nth-child(3) {
  margin-right: 24px;
}
input:nth-child(4) {
  margin-left: 24px;
}
.line {
  width: 11px;
  height: 1px;

  background: #2c2c2c;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
input:last-of-type {
  margin-right: 0;
}
input:disabled {
  color: #000;
  background-color: #fff;
}
.msg {
  text-align: center;
}
.act {
  // border: 1px solid #F67942;
  box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05),
    0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 8px 10px -5px rgba(0, 0, 0, 0.08);
}
.err {
  border: 1px solid #f53f3f !important;
  //border: 1px solid skyblue;
}
</style>
