<!--
 * @FilePath: /daren-html-source/src/views/FmCloud/index.vue
 * @Description: 飞猫盘
-->
<template>
  <div class="fmcloud">
    <div class="fmTop">
      <div class="fmtit">
        <span class="tit">{{ $t('FmCloud.DownloadRebateMode') }}</span>
        <span class="tips"
          >（{{ $t('FmCloud.YouHave') }}
          {{ Intl.NumberFormat('en-US').format(downTable.file_count) }}
          {{ $t('FmCloud.FilesUsingMode') }}）</span
        >
      </div>
      <div class="levelBox">
        {{ $t('FmCloud.MyGrade')
        }}<span
          class="tips"
          :style="`color:#${$utils.getLevelColor(
            $store.state.userInfo.user_plan.subject
          )}`"
          >{{ $store.state.userInfo.user_plan.subject }}</span
        >
      </div>
    </div>
    <div class="intr">
      {{ $t('FmCloud.TextOne')
      }}<span
        class="tips pointer"
        style="font-weight: bold"
        @click="toplay(0)"
        >{{ $t('FmCloud.DetailedIntroduction') }}</span
      >
    </div>

    <div class="echartBox">
      <div id="echart1" style="width: 932px; height: 405px"></div>
    </div>
    <!-- 暂未获奖 进行中 -->
    <div class="dayNum">
      <div class="item" v-for="item in downNumList" :key="item.id">
        <div class="daytit">{{ item.name }}</div>
        <div class="dayDaren" v-if="item.id == 2">
          <span class="status1" v-if="item.task_status == 1">{{
            $t('FmCloud.NotYetAwarded')
          }}</span>
          <fm-button
            class="btn"
            v-else
            @click="toRank(item)"
            :disabled="item.task_status == 3"
            type="primary"
            size="mini"
            >{{
              item.task_status == 2
                ? $t('FmCloud.ReceiveReward')
                : $t('FmCloud.Claimed')
            }}</fm-button
          >
        </div>
        <div class="dayNumber" v-else>
          {{ Intl.NumberFormat('en-US').format(item.num) }}
        </div>
        <div class="dayIntr">
          {{ item.id == 2 ? $t('FmCloud.TextFive') : auto_cash_update_info }}
        </div>
      </div>
    </div>
    <div v-show="$store.state.userInfo.daren_rebate_switch == 1">
      <div class="fmTop" style="margin-top: 64px">
        <div class="fmtit">
          <span class="tit">{{ $t('FmCloud.MemberRebateModel') }}</span>
          <span class="tips"
            >（{{ $t('FmCloud.YouHave') }}
            {{ Intl.NumberFormat('en-US').format(darenTable.file_count) }}
            {{ $t('FmCloud.FilesUsingMode') }}）</span
          >
        </div>
      </div>
      <div class="intr">
        {{ $t('FmCloud.TextSix')
        }}<span
          class="tips pointer"
          style="font-weight: bold"
          @click="toplay(0)"
          >{{ $t('FmCloud.DetailedIntroduction') }}</span
        >
      </div>
      <div class="echartBox">
        <div id="echart2" style="width: 932px; height: 405px"></div>
      </div>

      <div class="dayNum">
        <div class="item" v-for="item in memberList" :key="item.id">
          <div class="daytit">{{ item.name }}</div>
          <div class="dayNumber">
            {{ Intl.NumberFormat('en-US').format(item.num) }}
          </div>
          <div class="dayIntr">{{ auto_cash_update_info }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import {
  creditDataRendAPI,
  creditDataDarenRendAPI,
  cloudToTaskAPI,
} from '@/utils/apiList/fmp'
export default {
  data() {
    return {
      downNumList: [
        {
          id: 0,
          name: this.$t('FmCloud.TextTwo'),
          num: 0,
        },
        {
          id: 1,
          name: this.$t('FmCloud.TextThree'),
          num: 0,
        },
        {
          id: 2,
          name: this.$t('FmCloud.WeekAward'),
          num: 0,
          task_status: 1,
          taskId: 0,
        },
      ],
      memberList: [
        {
          id: 0,
          name: this.$t('FmCloud.VIPRebatesToday'),
          num: 0,
        },
        {
          id: 1,
          name: this.$t('FmCloud.CommissionBackToday'),
          num: 0,
        },
      ],

      downTable: {
        x: [],
        ymin: [],
        ymax: [],
        file_count: 0,
      },
      darenTable: {
        x: [],
        ymin: [], //返佣个数
        ymax: [], //返佣金额
        file_count: 0,
      },
      auto_cash_update_info: this.$t('FmCloud.TextFour'),
    }
  },
  mounted() {
    this.getData1()
    this.getData2()
  },
  methods: {
    initEchart1() {
      var chartDom = document.getElementById('echart1')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        legend: {
          data: [
            {
              name: this.$t('FmCloud.LargeFileIncome'),
              icon: 'path://M7,14a7,7,0,1,1,7-7A7,7,0,0,1,7,14ZM7,2.5A4.5,4.5,0,1,0,11.5,7,4.51,4.51,0,0,0,7,2.5Z',
            },
            {
              name: this.$t('FmCloud.SmallFileIncome'),
              icon: 'path://M7,14a7,7,0,1,1,7-7A7,7,0,0,1,7,14ZM7,2.5A4.5,4.5,0,1,0,11.5,7,4.51,4.51,0,0,0,7,2.5Z',
            },
          ],
          bottom: 0,
        },
        tooltip: {
          // 设置tip提示
          trigger: 'axis',
          formatter: function (params) {
            let result = ''
            result += 
            `<div style="font-weight: 400;font-size: 12px;color: #8A8B92;line-height: 20px;;margin-bottom:8px">
              ${params[0].name}
            </div>`;
            params.forEach(item =>{
              const symbolstyle = ` 
                width: 8px;
                height: 8px;
                display:inline-block;
                border: 2.5px solid ${item.color};
                border-radius: 50%; 
                background-color: transparent; `
              result +=`
                <div style="display:flex;justify-content:space-between;align-items:center">
                  <div>
                    <span style="${symbolstyle}"></span>
                    <span style="margin-left:5px;margin-right:18px;font-weight: 400;font-size: 12px;color:#2C2C2C;">
                      ${item.seriesName}
                    </span>
                  </div>
                  <span style="font-weight: 400;font-size: 12px;color: #2C2C2C;">${item.value}</span>
                </div>`;
            })
            return result
          },
          extraCssText:
            'box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.06);border-radius: 8px;',
        },
        grid: {
          // 调整图表上下左右位置
          left: 0,
          right: 30,
          containLabel: true,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#8a8b92', // 更改坐标轴文字颜色
              fontSize: 14, // 更改坐标轴文字大小
            },
          },
        },
        xAxis: {
          // 设置x轴
          type: 'category',
          data: this.downTable.x,
          axisLabel: {
            show: true,
            margin: 12,
            textStyle: {
              color: '#8a8b92', // 更改坐标轴文字颜色
              fontSize: 14, // 更改坐标轴文字大小
            },
          },
        },
        series: [
          {
            name: this.$t('FmCloud.LargeFileIncome'),
            data: this.downTable.ymax,
            z:2,
            type: 'line',
            symbol:
              'path://M7,14a7,7,0,1,1,7-7A7,7,0,0,1,7,14ZM7,2.5A4.5,4.5,0,1,0,11.5,7,4.51,4.51,0,0,0,7,2.5Z',
            tooltip: {
              show: true,
            },
            symbolSize: 2,
            smooth: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(236, 233, 255, 1)',
                },
                {
                  offset: 1,
                  color: 'rgba(236, 233, 255, 0)',
                },
              ]),
            },
            itemStyle: {
              normal: {
                color: 'rgba(126, 114, 246, 1)', // 改变折线点的颜色
                lineStyle: {
                  color: 'rgba(126, 114, 246, 1)', // 改变折线颜色
                  width: 2, // 设置线条粗细
                },
              },
            },
          },
          {
            name: this.$t('FmCloud.SmallFileIncome'),
            data: this.downTable.ymin,
            type: 'line',
            smooth: true,
            z:1,
            symbol:
              'path://M7,14a7,7,0,1,1,7-7A7,7,0,0,1,7,14ZM7,2.5A4.5,4.5,0,1,0,11.5,7,4.51,4.51,0,0,0,7,2.5Z',
            symbolSize: 2,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(216, 249, 225, 1)',
                },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, 0)',
                },
              ]),
            },
            itemStyle: {
              normal: {
                color: 'rgba(62, 214, 151, 1)', // 改变折线点的颜色
                lineStyle: {
                  color: 'rgba(62, 214, 151, 1)', // 改变折线颜色
                  width: 2, // 设置线条粗细
                },
              },
            },
          },
        ],
      }
      option && myChart.setOption(option)
    },
    initEchart2() {
      var chartDom = document.getElementById('echart2')
      var myChart = echarts.init(chartDom)
      var option
      ;(option = {
        legend: {
          data: [
            {
              name: this.$t('FmCloud.RebateVIP'),
              icon: 'path://M7,14a7,7,0,1,1,7-7A7,7,0,0,1,7,14ZM7,2.5A4.5,4.5,0,1,0,11.5,7,4.51,4.51,0,0,0,7,2.5Z',
            },
            {
              name: this.$t('FmCloud.CommissionRebate'),
              icon: 'path://M7,14a7,7,0,1,1,7-7A7,7,0,0,1,7,14ZM7,2.5A4.5,4.5,0,1,0,11.5,7,4.51,4.51,0,0,0,7,2.5Z',
            },
          ],
          bottom: 0,
        },
        tooltip: {
          // 设置tip提示
          trigger: 'axis',
          formatter: function (params) {
            let result = ''
            result += 
            `<div style="font-weight: 400;font-size: 12px;color: #8A8B92;line-height: 20px;;margin-bottom:8px">
              ${params[0].name}
            </div>`;
            params.forEach(item =>{
              const symbolstyle = ` 
                width: 8px;
                height: 8px;
                display:inline-block;
                border: 2.5px solid ${item.color};
                border-radius: 50%; 
                background-color: transparent; `
              result +=`
                <div style="display:flex;justify-content:space-between;align-items:center">
                  <div>
                    <span style="${symbolstyle}"></span>
                    <span style="margin-left:5px;margin-right:18px;font-weight: 400;font-size: 12px;color:#2C2C2C;">
                      ${item.seriesName}
                    </span>
                  </div>
                  <span style="font-weight: 400;font-size: 12px;color: #2C2C2C;">${item.value}</span>
                </div>`;
            })
            return result
          },
          extraCssText:
            'box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.06);border-radius: 8px;',
        },
        grid: {
          // 调整图表上下左右位置
          left: 0,
          right: 30,
          containLabel: true,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#8a8b92', // 更改坐标轴文字颜色
              fontSize: 14, // 更改坐标轴文字大小
            },
          },
        },
        xAxis: {
          // 设置x轴
          type: 'category',
          data: this.darenTable.x,

          axisLabel: {
            show: true,
            margin: 12,
            textStyle: {
              color: '#8a8b92', // 更改坐标轴文字颜色
              fontSize: 14, // 更改坐标轴文字大小
            },
          },
        },

        series: [
          {
            name: this.$t('FmCloud.RebateVIP'),
            data: this.darenTable.ymin,
            z:2,
            type: 'line',
            symbolSize: 2,
            smooth: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(236, 233, 255, 1)',
                },
                {
                  offset: 1,
                  color: 'rgba(236, 233, 255, 0)',
                },
              ]),
            },
            itemStyle: {
              normal: {
                color: 'rgba(126, 114, 246, 1)', // 改变折线点的颜色
                lineStyle: {
                  color: 'rgba(126, 114, 246, 1)', // 改变折线颜色
                  width: 2, // 设置线条粗细
                },
              },
            },
          },
          {
            name: this.$t('FmCloud.CommissionRebate'),
            data: this.darenTable.ymax,
            z:1,
            type: 'line',
            smooth: true,
            symbolSize: 2,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(216, 249, 225, 1)',
                },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, 0)',
                },
              ]),
            },
            itemStyle: {
              normal: {
                color: 'rgba(62, 214, 151, 1)', // 改变折线点的颜色
                lineStyle: {
                  color: 'rgba(62, 214, 151, 1)', // 改变折线颜色
                  width: 2, // 设置线条粗细
                },
              },
            },
          },
        ],
      }),
        option && myChart.setOption(option)
    },
    toplay(id) {
      this.$router.push({ path: '/help', query: { id: id } })
    },
    getData1() {
      creditDataRendAPI()
        .then(res => {
          if (res.status == 1) {
            this.downTable.x = res.data.date_list
            this.downTable.ymin = res.data.list.map(item => item.nums1)
            this.downTable.ymax = res.data.list.map(item => item.nums2)
            this.downTable.file_count = parseInt(res.data.file_count)
            this.initEchart1()
            this.downNumList[1].num = res.data.lower_lewltotal
            this.downNumList[0].num = res.data.over_lewltotal
            this.downNumList[2].task_status = res.data.task.task_status
            this.downNumList[2].taskId = res.data.task.tid
            this.auto_cash_update_info = res.data.auto_cash_update_info
          }
        })
        .catch(err => {})
    },
    getData2() {
      creditDataDarenRendAPI()
        .then(res => {
          if (res.status == 1) {
            this.darenTable.x = res.data.date_list
            this.darenTable.ymin = res.data.list.map(item => item.nums1)
            this.darenTable.ymax = res.data.list.map(item => item.nums2)
            this.darenTable.file_count = parseInt(res.data.file_count)

            this.initEchart2()
            //个数
            this.memberList[1].num = res.data.lower_lewltotal
            //金额
            this.memberList[0].num = res.data.over_lewltotal
          }
        })
        .catch(err => {})
    },
    toRank(item) {
      if (item.task_status == 3) {
        return
      }
      cloudToTaskAPI().then(res => {
        if (res.status == 1) {
          this.getData2()
          this.getData1()
          this.$toast(res.msg, 1)
        } else {
          this.$toast(res.msg, 2)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
