<!--
 * @FilePath: /daren-html-source/src/views/Help/contactCust.vue
 * @Description: 联系客服组件
-->
<template>
  <div class="contactCust">
    <div class="pub-tit0">{{ $t('help.contactCust.HotIssue') }}</div>
    <div class="qTop">
      <div class="questionBox">
        <div
          class="qItem"
          @click="gohelpDetail(item)"
          v-for="item in questionList.slice(
            0,
            Math.ceil(questionList.length / 2)
          )"
          :key="item"
        >
          <div class="tit">
            {{ item.title }}
          </div>
          <div class="btn">
            {{ $t('help.contactCust.View') }}
            <svg-btn class="svgBtn" icon-class="icon-to" />
          </div>
        </div>
      </div>
      <div class="questionBox">
        <div
          class="qItem"
          v-for="item in questionList.slice(
            Math.ceil(questionList.length / 2),
            questionList.length
          )"
          :key="item"
          @click="gohelpDetail(item)"
        >
          <div class="tit">
            {{ item.title }}
          </div>
          <div class="btn">
            {{ $t('help.contactCust.View') }}
            <svg-btn class="svgBtn" icon-class="icon-to" />
          </div>
        </div>
      </div>
    </div>
    <div class="pub-tit0" style="margin: 40px 0 20px 0">
      {{ $t('help.contactCust.OtherHelp') }}
    </div>

    <div class="list">
      <div class="item" v-for="item in list" :key="item.id">
        <img :src="$utils.getPng('icon_help' + item.id)" alt="" />
        <div class="info">
          <div class="tit">{{ item.name }}</div>
          <div class="btn pointer" @click="toLink(item)">
            {{ item.name1 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getQuestionsAPI } from '@/utils/apiList/user'
export default {
  data() {
    return {
      list: [
        {
          id: 0,
          name: this.$t('help.contactCust.SubmitWorkOrder'),
          name1: this.$t('help.contactCust.SubmitNow') + ' >',
          path: 'submitQuestions',
        },
        {
          id: 1,
          name: this.$t('help.contactCust.FeedbackHistory'),
          name1: this.$t('help.contactCust.GoToView') + ' >',
          path: 'help',
        },
        {
          id: 2,
          name: this.$t('help.contactCust.TextOne'),
          name1: this.$t('help.contactCust.TextTwo') + ' >',
          path: 'help',
        },
      ],
      questionList: [],
    }
  },
  created() {
    this.getQuestion()
  },
  methods: {
    toLink(item) {
      if (item.id == 0) {
        window.open(`${this.$glb.fmpUrl}/submitQuestions`, '_blank')
      } else if (item.id == 1) {
        window.open(`${this.$glb.fmpUrl}/help?id=1`, '_blank')
      } else if (item.id == 2) {
        window.open('http://q.url.cn/CDKEvl?_type=wpa&qidian=true')
      }
    },
    getQuestion() {
      getQuestionsAPI({
        tid: 0,
      }).then(res => {
        if (res.status == 1) {
          this.questionList = res.data.list
        }
      })
    },
    gohelpDetail(item) {
      window.open(`${this.$glb.fmpUrl}/askdetail/${item.id}`, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.contactCust {
  .qTop {
    display: flex;
    justify-content: space-between;
    .questionBox {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      width: 454px;
      background: #f9f9f9;
      border-radius: 16px;
      padding: 4px 16px;
      .qItem {
        width: 422px;
        height: 54px;
        box-shadow: inset 0px -1px 0px 0px #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #2c2c2c;
        cursor: pointer;
        .tit {
          @include multiline(1);
        }

        .btn {
          height: 54px;
          display: flex;
          margin-left: 32px;
          align-items: center;
          flex-shrink: 0;
          .svgBtn {
            margin-left: 8px;
          }
        }
      }
      :hover {
        color: #7E72F6;
      }
      :last-child {
        box-shadow: inset 0px 0px 0px 0px #e5e5e5;
      }
    }
  }
  .list {
    display: flex;
    margin-top: 20px;
    .item {
      display: flex;
      width: 200px;
      margin-right: 80px;
      img {
        width: 52px;
        height: 52px;
      }
      .info {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        justify-content: space-between;
        .tit {
          height: 22px;
          font-weight: 500;
          font-size: 14px;
          color: #2c2c2c;
          line-height: 22px;
          white-space: nowrap;
        }
        .btn {
          height: 22px;
          font-weight: 400;
          font-size: 14px;
          color: #8a8b92;
          line-height: 22px;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
