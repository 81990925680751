<!--
 * @FilePath: /daren-html-source/src/components/TablePublic/index.vue
 * @Description:  表格组件
 
-->
<template>
    <div class="tableMemNum">
      <div class="title" style="margin: 40px 0 20px 0">
        <span style="margin-right: 4px;">{{ tableTitle }}</span>
        <el-tooltip v-if="amount" class="item" effect="dark" content="" placement="right">
          <template v-slot:content>
            <div style="line-height: 1.5em;max-width: 600px;">
              <p>{{$t('OfflineRebate.offIncomeData.TextThree')}}</p>
              <p>{{$t('OfflineRebate.offIncomeData.TextFour')}}</p>
              <p>{{$t('OfflineRebate.offIncomeData.TextFive')}}</p>
            </div>
          </template>
          <svg-btn icon-class="icon-tips" />
        </el-tooltip>
      </div>
      <div class="tableHeader">
        <div class="table0 cell" >{{$t('OfflineRebate.InvitationLink.UserNickname')}}</div>
        <div class="table1 cell">{{ amount ? $t('OfflineRebate.offIncomeData.TextTwo') : $t('OfflineRebate.InvitationLink.TextFive') }}</div>
        <div class="table2 cell" >{{$t('OfflineRebate.InvitationLink.InvitationTime')}}</div>
      </div>
      <div class="tableContent" v-loading="tableLoading">
        <div class="empty" v-if="total == 0">
          <template v-if="!tableLoading">
            <img class="emptyImg" :src="$utils.getPng('icon_new_empty')" alt="" />
            <div class="emptyText">{{ $t('TablePublic.NoRecord') }}</div>
          </template>
        </div>
        <div v-else>
          <div class="item" v-for="item in tableList" :key="item">
            <div class="table0 cell">{{ item.nickname }}</div>
            <div class="table1 cell">
              {{ amount ? item.invited_back: item.is_expert === "1" ? $t('OfflineRebate.InvitationLink.HaveOpened') : $t('OfflineRebate.InvitationLink.HaveNotOpened') }}
            </div>
            <div class="table2 cell">{{ item.invite_time }}</div>
          </div>
  
          <div class="pagation">
            <PaginationCom
              :total="total"
              :pageSize="30"
              @changepage="changepage"
            ></PaginationCom>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import PaginationCom from "@/components/pagination/index.vue";
  
  export default {
    components: {
      PaginationCom,
    },
    props: {
      tableList: {
        type: Array,
        default: () => [],
      },
      tableTitle: {
        type: String,
        default: () => "开通会员人数（35）",
      },
      amount: Boolean,
      tableHeader: {
        type: Array,
        default: () => ["用户昵称", "开通时间", "返利金额"],
      },
      total: {
        type: Number,
        default: () => 0,
      },
    },
    data() {
      return {
        tableLoading: false,
      };
    },
    methods: {
      changepage(page) {
        this.$parent?.getinviteNumPeopleList(page);
        this.$store.commit("toTop");
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .tableMemNum {
    .title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 16px;
      color: #2C2C2C;
      line-height: 26px;
      display: flex;
      align-items: center;
    }
    .cell {
      padding-right: 24px;
    }
    .table0 {
      width: 400px;
      @include multiline(1);
    }
    .table1 {
      width: 250px;
    }
    .table2 {
      width: 206px;
    }
    .tableHeader {
      width: 100%;
      height: 52px;
      background: #f9f9f9;
      border-radius: 12px;
      font-weight: 500;
      font-size: 14px;
      color: #8a8b92;
      display: flex;
      align-items: center;
      padding: 0 24px;
    }
    .tableContent {
      display: flex;
      flex-direction: column;
      .empty {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        height: 128px;
        margin: 104px 0 68px;
        .emptyImg {
          width: 100px;
          height: 100px;
        }
        .emptyText {
          margin-top: 8px;
          font-size: 16px;
          color: #8a8b92;
        }
      }
      .item {
        font-size: 14px;
        color: #2c2c2c;
        display: flex;
        align-items: center;
        padding: 0 24px;
        height: 56px;
        box-shadow: inset 0px -1px 0px 0px #f6f6f6;
      }
      .pagation {
        margin-left: auto;
        margin-right: 0;
        margin-top: 64px;
        display: flex;
        justify-content: flex-end;
        :deep(.jumper .el-input__inner) {
          text-align: center;
        }
        :deep(.fm-button) {
          padding: 0 14px;
          height: 36px;
          border-radius: 8px;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
  </style>
  