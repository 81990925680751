<!--
 * @FilePath: /daren-html-source/src/views/Help/fmp.vue
 * @Description: help 飞猫盘tab
-->
<template>
  <div class="fmp">
    <div class="pub-tit0">{{ $t('help.fmp.EarningsProfile') }}</div>
    <div class="intrBox">
      <div class="one">
        <span class="c2c"
          >{{ $t('help.fmp.TextOne') }}</span
        >
      </div>
      <div class="one">
        <span class="c2c">{{ $t('help.fmp.TextTwo') }}</span>{{ $t('help.fmp.TextThree') }}
        <span class="c2c">{{ $t('help.fmp.TextFour') }}</span>{{ $t('help.fmp.TextFive') }}<span
          class="c2c"
          >{{ $t('help.fmp.TextSix') }}</span
        >{{ $t('help.fmp.TextSeven') }}<span class="c2c">{{ $t('help.fmp.TextEight') }}</span>
        {{ $t('help.fmp.TextNine') }}
      </div>
      <div class="one">
        <span class="c2c">{{ $t('help.fmp.TextTen') }}</span
        >{{ $t('help.fmp.TextEleven') }}
        <span class="c2c">{{ $t('help.fmp.TextTwelve') }}</span>{{ $t('help.fmp.TextThirteen') }}<span class="c2c">{{ $t('help.fmp.TextFourteen') }}</span
        >{{ $t('help.fmp.TextFifteen') }}
        <!-- <span class="c2c">{{ $t('help.fmp.TextSixteen') }}</span>{{ $t('help.fmp.TextSeventeen') }} -->
      </div>
    </div>
    <div v-show="$store.state.userInfo.daren_rebate_switch == 1">
      <div class="pub-tit0 tit">{{ $t('help.fmp.IncomeModelComparison') }}</div>
      <div class="modeTable">
        <div class="modeHead">
          <div class="mhead0">{{ $t('help.fmp.Equity') }}</div>
          <div class="mhead1">
            {{ $t('help.fmp.DownloadsRebateModel') }}
            <img class="imgicon icon0" :src="$utils.getPng(icon8)" alt="" />
          </div>
          <div class="mhead1">
            {{ $t('help.fmp.VIPRebateModel') }}
            <img class="imgicon icon1" :src="$utils.getPng(icon7)" alt="" />
          </div>
          <div class="mhead2">{{ $t('help.fmp.MoreModes') }}</div>
        </div>
        <div class="modeContent" v-loading="loadingFlag">
          <div class="colum0">
            <div class="item" v-for="(item, index) in list[0]" :key="index">
              {{ item.value_1 }}
            </div>
          </div>
          <div class="colum1">
            <div
              class="mhead1 item1"
              v-for="(item, index) in list[1]"
              :key="index"
            >
              <div class="intrBox1" v-if="item.type == 1">
                <div class="intr1">{{ item.value_1 }}</div>
                <div class="intr2" v-if="item.value_2">{{ item.value_2 }}</div>
              </div>
              <img
                v-if="item.type == 2"
                class="icon_check"
                :src="$utils.getPng('icon_check')"
                alt=""
                srcset=""
              />
              <div class="intrBox1" v-if="item.length == 0">
                <div class="intr1">-</div>
              </div>
            </div>
          </div>
          <div class="colum1">
            <div
              class="mhead1 item1"
              v-for="(item, index) in list[2]"
              :key="index"
            >
              <div class="intrBox1" v-if="item.type == 1">
                <div class="intr1">{{ item.value_1 }}</div>
                <div class="intr2" v-if="item.value_2">{{ item.value_2 }}</div>
              </div>
              <div class="intrBox1" v-if="item.length == 0">
                <div class="intr1">-</div>
              </div>
              <img
                v-if="item.type == 2"
                class="icon_check"
                :src="$utils.getPng('icon_check')"
                alt=""
                srcset=""
              />
            </div>
          </div>
          <div class="colum1">
            <div
              class="mhead2 item1"
              v-for="(item, index) in list[2]"
              :key="index"
            >
              <div class="intrBox1">
                <div class="intr1">-</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pub-tit0" style="margin-top: 40px">{{ $t('help.fmp.DownloadRebateRules') }}</div>
    <div class="intrBox">
      <div class="one">
        <span class="c2c">{{ $t('help.fmp.RebateMechanism') }}</span
        >{{ $t('help.fmp.TextEighteen') }}
      </div>
      <div class="one">
        <span class="c2c"> {{ $t('help.fmp.levelRules') }}</span
        >{{ $t('help.fmp.TextNineteen') }}
      </div>
      <div class="one">
        <span class="c2c"> {{ $t('help.fmp.doubleRevenue') }}</span
        >{{ $t('help.fmp.TextTwenty') }}
      </div>
    </div>
    <LevelTable style="margin-top: 20px"></LevelTable>
    <div v-show="$store.state.userInfo.daren_rebate_switch == 1">
      <div class="pub-tit0" style="margin-top: 40px" @click="toTop">
        {{ $t('help.fmp.MemberRebateModel') }}
      </div>
      <div class="intrBox">
        <div class="one">
          <span class="c2c">{{ $t('help.fmp.RebateMechanismTwo') }}</span
          >{{ $t('help.fmp.twentyOne') }}{{
            open_vip_rebate
          }}{{ $t('help.fmp.twentyTwo') }}
        </div>
        <div class="one">
          <span class="c2c"> {{ $t('help.fmp.multiplePurchaseRebate') }}</span
          >{{ $t('help.fmp.twentyThree') }}{{
            open_vip_rebate
          }}{{ $t('help.fmp.twentyFour') }}
        </div>

        <div class="one">
          <span class="c2c">{{ $t('help.fmp.BindingRestrictions') }}</span
          >{{ $t('help.fmp.twentyFive') }}
        </div>

        <div class="one">
          <span class="c2c">{{ $t('help.fmp.discountCode') }}</span
          >{{ $t('help.fmp.twentySix') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LevelTable from "@/components/LevelTable/index.vue";
import { fileModelInfoAPI } from "@/utils/apiList/offlineRebate";
export default {
  components: {
    LevelTable,
  },
  data() {
    return {
      list: [],
      open_vip_rebate: "30%",
      loadingFlag: false,
      icon7:'',
      icon8:'',
      lang:'',
    };
  },
  watch:{
    lang(){
      if(this.lang == 'en'){
        this.icon7 = 'icon7En'
        this.icon8 = 'icon8En'
      }else if(this.lang == 'zhhk'){
        this.icon7 = 'icon7Hk'
        this.icon8 = 'icon8'
      }else{
        this.icon7 = 'icon7'
        this.icon8 = 'icon8'
      }
    }
  },
  created() {
    this.lang = localStorage.getItem('lang')
    this.fileMode();
  },
  methods: {
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    fileMode() {
      this.loadingFlag = true;
      fileModelInfoAPI().then((res) => {
        this.loadingFlag = false;
        if (res.status == 1) {
          this.list = res.data.list;
          this.open_vip_rebate = res.data.open_vip_rebate;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fmp {
  .tit {
    margin: 40px 0 20px 0;
  }
  .modeTable {
    display: flex;
    flex-direction: column;

    // overflow: hidden;
    .mhead0 {
      width: 188px;
      height: 22px;
      font-weight: 500;
      font-size: 14px;
      color: #8a8b92;
    }
    .mhead1 {
      width: 282px;
      font-weight: 500;
      font-size: 14px;
      color: #2c2c2c;
      position: relative;
      text-align: center;
      .imgicon {
        position: absolute;
        top: -21px;
      }
    }
    .mhead2 {
      width: 132px;
      font-weight: 500;
      font-size: 14px;
      color: #2c2c2c;
      text-align: left;
    }
    .modeHead {
      width: 932px;
      height: 52px;
      border-radius: 12px;
      padding: 0 24px;
      display: flex;
      align-items: center;
      background: #f9f9f9;
      .icon0 {
        min-width: 52px;
        height: 24px;
      }
      .icon1 {
        min-width: 52px;
        height: 24px;
      }
    }
    .modeContent {
      display: flex;
      min-height: 400px;

      .colum0 {
        display: flex;
        flex-direction: column;
        width: 188px;
        margin-left: 24px;
        flex-shrink: 0;
        .item {
          height: 64px;
          line-height: 64px;
          box-shadow: inset 0px -1px 0px 0px #f6f6f6;
        }
      }
      .colum1 {
        display: flex;
        flex-direction: column;
        .item1 {
          height: 64px;
          line-height: 64px;
          box-shadow: inset 0px -1px 0px 0px #f6f6f6;
          color: #8a8b92;
          display: flex;
          align-items: center;
          justify-content: center;
          .intrBox1 {
            display: flex;
            flex-direction: column;
            .intr1 {
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
            }
            .intr2 {
              height: 20px;
              font-size: 12px;
              line-height: 20px;
            }
          }
          .icon_check {
            width: 20px;
            height: 20px;
          }
        }
        .item2 {
          color: #8a8b92;
          text-align: center;
        }
      }
    }
  }
}
</style>
