//不要格式化
export default {
  home: {
    Login: '登 錄', // 登录
    Sign: '註 冊', // 立即注册
    Slogan: '分享每一刻 收穫每一刻', //分享每一刻 收获每一刻
    ImmedSign: '立即註冊', // 立即注册
    Enter: '進入CloudMaster', // 进入CloudMaster
    Signup: '註冊FEEMOO賬號', // 注册飞猫盘账号
    Simple: '超簡單極速註冊', // 超简单极速注册
    Master: '開通CloudMaster', // 开通飞猫达人
    Exclusive: '達人專屬標識/權益', //达人专属标识/权益
    ShareFile: '分享文件賺收益', // 分享文件赚收益
    Others: '他人下載/轉存即獲得收益', // 他人下载/转存即获得收益
    Advantages: '平臺優勢', // 平台优势
    Multiple: '多種模式靈活玩法多樣', //多种模式灵活玩法多样
    CardTitle1: 'FEEMOO返利', // FEEMOO返利
    CardText1: 'FEEMOO是分享型網盤，成爲達人後上傳文件，生成分享鏈接供他人下載。', // 
    CardText1_2prefix: '可選擇', //可选择
    CardText1_2Blod: '下載量返利模式', //下载量返利模式
    CardText1_2suffix: '，區分大小文件按下載量返利，區分等級萬次下載最高1888元，每週三、週四雙倍收益。', //，区分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。
    CardText1_3prefix: '或選擇', //或选择
    CardText1_3Blod: '會員返利模式', //会员返利模式
    CardText1_3suffix: '，不按下載次數計費，只根據下載用戶開通會員進行按比例分成，文件不過期，頁面更簡潔。', //，不按下载次数计费，只根据下载用户开通会员进行按比例分成，文件不过期，页面更简洁。
    CardTitle2: '優惠碼返利', // 优惠码返利
    CardText2: '使用專屬您的邀請碼邀請用戶開通FEEMOO會員，您可獲得返利收益。', // 使用专属您的邀请码邀请用户开通飞猫盘会员，您可获得返利收益
    CardTitle3: '下線返利', // 下线返利
    CardText3: '好友通過邀請鏈接註冊FEEMOO賬號後申請成爲CloudMaster，您可獲得好友收益的分成返利。', // 好友通过邀请链接注册飞猫盘账号后申请成为飞猫达人，您可获得好友收益的分成返利。
    Income: '真實收益', // 真实收益
    Random: '隨機展示上週部分收益提現記錄 真實提現看得到', // 随机展示上周部分收益提现记录 真实提现看得到
    Feedback: '真實用戶反饋', // 真实用户反馈
    Realization: '上線十年流量變現平臺，真實反饋', // 上线十年流量变现平台，真实反馈
    Enjoy: '享受分享的樂趣，輕鬆賺取屬於你的變現收益', // 享受分享的乐趣，轻松赚取属于你的变现收益。
  },
  nav: { //头部模块
    Login: '登 錄', //登录
    Sign: '註 冊', //注册
    Account: '個人賬戶', //个人账户
    SignOut: '退出', //退出
  },
  GradeColor: { //等级颜色
    Bronze:'青銅',//青铜
    Silver:'白銀',//白银
    Gold:'黃金',//黄金
    Diamond:'鑽石',//钻石
    Master:'大師',//大师
    King:'王者',//王者
  },
  IntrDaren: { //未登录展示
    FeeMooMaster: '飛貓達人', //飞猫达人
    TextOne: '開通成爲飛貓達人後即可解鎖多項推廣返利功能！', //开通成为飞猫达人后即可解锁多项推广返利功能！
    OpenVIP: '立即開通', //立即开通
    PlatformPlay: '平臺玩法', //平台玩法
    PlateRebate: '飛貓盤返利', //飞猫盘返利
    TextFive: '分享文件給其他用戶下載/轉存獲得收益', //分享文件给其他用户下载/转存获得收益
    PromoCodeRebate: '優惠碼返利', //优惠码返利
    TextSix: '用戶通過優惠碼開通會員獲得返利收益', //用户通过优惠码开通会员获得返利收益
    DownlineRebate: '下線返利', //下线返利
    TextSeven: '邀請好友成爲飛貓達人獲得好友的收益分成', //邀请好友成为飞猫达人获得好友的收益分成
    ActivityRule: '活動規則', //活动规则
    TextTwo: '申請人需完善收款人信息', //申请人需完善收款人信息
    TextThree: '需要遵守平臺規則', //需遵守平台规则
    TextFour: '《飛貓盤用戶服務協議》', //《飞猫盘用户服务协议》
  },
  FmCloud: { //飞猫盘模块
    DownloadRebateMode: '下載量返利模式', //下载量返利模式
    YouHave: '您有', //您有
    FilesUsingMode: '個文件使用該模式', //个文件使用该模式
    MyGrade: '我的等級：', //我的等级：
    TextOne:
      '區分大小文件按下載量返利，區分等級萬次下載最高1888元，每週三、週四雙倍收益，', //区分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益，
    DetailedIntroduction: '詳細介紹', //详细介绍
    LargeFileIncome: '大文件收益', //大文件收益
    SmallFileIncome: '小文件收益', //小文件收益
    TextTwo: '今日大於100M文件下載量（次）', //今日大于100M文件下载量（次）
    TextThree: '今日小於100M文件下載量（次）', //今日小于100M文件下载量（次）
    WeekAward: '每週飛貓達人獎', //每周飞猫达人奖
    TextFour: '次日上午8點自動兌換到餘額', //次日上午8点自动兑换到余额
    NotYetAwarded: '暫未獲獎', //暂未获奖
    ReceiveReward: '領取獎勵', //领取奖励
    Claimed: '已領取', //已领取
    MemberRebateModel: '會員返利模式', //会员返利模式
    TextFive: '每週三評獎', //每周三评奖
    TextSix:
      '不按下載次數計費，只根據下載用戶開通會員進行按比例分成，文件不過期，頁面更簡潔，', //不按下载次数计费，只根据下载用户开通会员进行按比例分成，文件不过期，页面更简洁，
    RebateVIP: '返傭個數', //返佣个数
    CommissionRebate: '返佣金額', //返佣金额
    VIPRebatesToday: '今日返傭個數', //今日返佣个数
    CommissionBackToday: '今日返佣金額', //今日返佣个数
  },
  NavLeft: { //左侧菜单模块
    FEEMOO:'飛貓盤',//飞猫盘
    PromotionCode:'優惠碼',//优惠码
    DownlineRebate:'下線返利',//下线返利
    HelpCenter:'幫助中心',//帮助中心
    BalanceWithdrawal:'餘額提現',//余额提现
    AccountManagement:'賬號管理',//账号管理
  },
  MemberRebate: { //优惠码模块
    RebateCode:'返利優惠碼',//返利优惠码
    IncomeData:'收益數據',//收益数据
    //返利优惠码模块
    rebateDisCode:{
      YourCurrentLevel:'您當前等級：',//您当前等级：
      Levels:'等級介紹',//等级介绍
      CouponCodeDiscount:'優惠碼折扣',//优惠码折扣
      RebateRatio:'返利比例',//返利比例
      MyPromoCode:'我的優惠碼：',//我的优惠码：
      MyCode:'我的優惠碼',//我的优惠码
      Copy:'複製',//复制
      CopyCode: '複製優惠碼', //复制优惠码
      MemberRebateIncome:'會員返利收益',//会员返利收益
    },
    //收益数据模块
    incomeData:{
      MemberRebateProgram:'會員返利計劃',//会员返利计划
      TextOne:'用戶通過優惠碼開通會員，您可獲得收益',//用户通过优惠码开通会员，您可获得收益
      Levels:'等級介紹',//等级介绍
      MyGrade: '我的等級：', //我的等级：
      MemberRebateIncome:'會員返利收益',//会员返利收益
      OpenMembership:'開通會員人數',//开通会员人数
      UserNickname:'用戶暱稱',//用户昵称
      OpeningTime:'開通時間',//开通时间
      RebateAmount:'返利金額',//返利金额
    },
  },
  OfflineRebate: { //下线返利模块
    InviteRegisterLink:'邀請註冊鏈接',//邀请注册链接
    IncomeData:'收益數據',//收益数据
    yuan:'元',//元
    InvitationLink:{ //邀请注册链接
      Text:'玩法說明',//玩法说明
      TextOne:'好友通過邀請鏈接註冊飛貓盤賬號後申請成爲飛貓達人，您可獲得好友收益的高額返利。',//好友通过邀请链接注册飞猫盘账号后申请成为飞猫达人，您可获得好友收益的高额返利。
      TextTwo:'需通過你的邀請鏈接註冊飛貓盤賬號成功，並且申請開通成爲飛貓達人。',//需通过你的邀请链接注册飞猫盘账号成功，并且申请开通成为飞猫达人
      TextThree:'好友成爲達人後獲得飛貓盤返利收益，你就能獲得好友的收益分成。',//好友成为达人后获得飞猫盘返利收益，你就能获得好友的收益分成。
      TextFour:'邀請越多好友成爲達人，獲得更多收益分成，上不封頂。',//邀请越多好友成为达人，获得更多收益分成，上不封顶
      InviteNow:'立即邀請',//立即邀请
      CopyInvitationLink:'複製邀請鏈接',//复制邀请链接
      SavePicture:'保存圖片',//保存图片
      DownLineIncome:'下線收益',//下线收益
      Save:'保存',//保存
      TodayEarnings:'今日收益',//今日收益
      Yesterday:'昨日',//昨日
      CurrentEarnings:'本月收益',//本月收益
      SeeMore:'查看更多',//查看更多
      InvitationRecord:'邀請記錄',//邀请记录
      UserNickname:'用戶暱稱',//用户昵称
      TextFive:'是否開通CloudMaster',//是否开通飞猫达人
      InvitationTime:'邀請註冊時間',//邀请注册时间
      TextSix:'獲得99元下線返利',//获得99元下线返利
      HaveOpened:'已開通',//已开通
      HaveNotOpened:'未開通',//未开通
    },
    offIncomeData:{ //收益数据
      TodayEarnings:'今日收益',//今日收益
      YesterdayEarnings:'昨日收益',//昨日收益
      CurrentEarnings:'本月收益',//本月收益
      TextOne:'收益記錄',//收益记录
      UserNickname:'用戶暱稱',//用户昵称
      TextTwo:'返利金額（元）',//返利金额（元）
      TextThree:'爲什麼我還沒有獲得下線返利收益：',//为什么我还没有获得下线返利收益：
      TextFour:'1、檢查好友狀態：請查看邀請記錄，確認好友是否已成爲飛貓達人。',//1、检查好友状态：请查看邀请记录，确认好友是否已成为飞猫达人。
      TextFive:'2、等待收益結算：若好友已是飛貓達人，需等待他們產生收益並在次日結算後，您才能獲得返利收益分成。',//2、等待收益结算：若好友已是飞猫达人，需等待他们产生收益并在次日结算后，您才能获得返利收益分成。
    },
  },
  help: { //帮助中心模块
    FEEMOO:'飛貓盤',//飞猫盘
    CouponCode:'優惠碼',//优惠码
    DownlineRebate:'下線返利',//下线返利
    ComplaintBox:'意見箱',//意见箱
    ContactCustomer:'聯繫客服',//联系客服
    fmp:{ //飞猫盘
      EarningsProfile:'收益簡介',//收益简介
      TextOne:'分享文件給其他用戶下載/轉存你就能獲得收益，每日所得收益次日凌晨自動結算。',//分享文件给其他用户下载/转存你就能获得收益，每日所得收益次日凌晨自动结算。
      TextTwo:'下載量返利模式：',//下载量返利模式：
      TextThree:'區分大小文件按',//区分大小文件按
      TextFour:'下載量返利',//下载量返利
      TextFive:'，區分等級萬次下載最高',//，区分等级万次下载最高
      TextSix:'1888元',//1888元
      TextSeven:'，每週三、週四',//，每周三、周四
      TextEight:'雙倍',//双倍
      TextNine:'收益。',//收益。
      TextTen:'會員返利模式：',//会员返利模式：
      TextEleven:'不按下載次數計費，只根據下載用戶開通會員進行按',//不按下载次数计费，只根据下载用户开通会员进行按
      TextTwelve:'比例分成',//比例分成
      TextThirteen:'，文件',//，文件
      TextFourteen:'不過期',//不过期
      TextFifteen:'，頁面更簡潔。',//，页面更简洁，适合
      TextSixteen:'大文件',//大文件
      TextSeventeen:'分享。',//分享。
      IncomeModelComparison:'收益模式對比',//收益模式对比
      Equity:'權益',//权益
      DownloadsRebateModel:'下載量返利模式',//下载量返利模式
      VIPRebateModel:'會員返利模式',//会员返利模式
      MoreModes:'更多模式即將上線',//更多模式即将上线
      DownloadRebateRules:'下載量返利模式規則',//下载量返利模式规则
      RebateMechanism:'1、返利機制：',//1、返利机制：
      TextEighteen:'按當日達人等級與當日所產生的下載量，在次日凌晨自動結算返利收益金額。',//按当日达人等级与当日所产生的下载量，在次日凌晨自动结算返利收益金额。
      levelRules:'2、等級規則：',//2、等级规则：
      TextNineteen:'根據昨日所產生的下載量來確定該模式下的達人返利等級，例如昨日下載量達到302，則今日達人等級爲黃金。',//根据昨日所产生的下载量来确定该模式下的达人返利等级，例如昨日下载量达到302，则今日达人等级为黄金。
      doubleRevenue:'3、雙倍收益：',//3、双倍收益：
      TextTwenty:'每週三、週四可獲得雙倍的下載量返利收益。',//每周三、周四可获得双倍的下载量返利收益。
      MemberRebateModel:'會員返利模式規則',//会员返利模式规则
      RebateMechanismTwo:'1、返利機制：',//1、返利机制：
      twentyOne:'選擇會員返利模式後，其他用戶通過你分享的下載鏈接訪問，在下載或轉存文件後，將與你建立24小時的綁定關係。 在綁定期內，若用戶在網頁端或APP端上開通會員，你將獲得其會員費用的',
      twentyTwo:'作爲返利。',//作为返利。
      multiplePurchaseRebate:'2、多次購買返利：',//2、多次购买返利：
      twentyThree:'在24小時綁定期內，用戶若多次開通會員，每次開通會員金額的',//在24小时绑定期内，用户若多次开通会员，每次开通会员金额的
      twentyFour:'均將作爲您的返利。',//均将作为您的返利。
      BindingRestrictions:'3、綁定關係限制：',//3、绑定关系限制：
      twentyFive:'一旦用戶與您建立了綁定關係，他們在24小時內將無法與其他達人建立新的綁定關係。',//一旦用户与您建立了绑定关系，他们在24小时内将无法与其他达人建立新的绑定关系。
      discountCode:'4、優惠碼返利限制：',//4、优惠码返利限制：
      twentySix:'如果用戶在開通會員時使用了優惠碼，平臺將根據優惠碼的使用情況，向你發放相應的返利收益。',//如果用户在开通会员时使用了优惠码，平台将根据优惠码的使用情况，向你发放相应的返利收益。
    },
    discountCode:{ //优惠码
      TextOne:'什麼是優惠碼？',//什么是优惠码？
      TextTwo:'好友通過您的優惠碼開通會員享折扣，同時您可獲得高額返利；達人等級越高，優惠碼折扣越大。',//好友通过您的优惠码开通会员享折扣，同时您可获得高额返利；达人等级越高，优惠码折扣越大。
      MasterRating:'達人等級',//达人等级
    },
    rebate:{ //下线返利
      TextOne:'什麼是下線返利？',//什么是下线返利？
      TextTwo:'用戶A邀請用戶B註冊，則用戶B成爲用戶A的下線：',//用户A邀请用户B注册，则用户B成为用户A的下线：
      TextThree:'平臺按用戶B在飛貓盤推廣活動中獲得收益的15%額外給用戶A獎勵，持續有效。',//平台按用户B在飞猫盘推广活动中获得收益的15%额外给用户A奖励，持续有效。
      MasterRating:'達人等級',//达人等级
    },
    contactCust:{ //联系客服
      HotIssue:'熱門問題',//热门问题
      View:'查看',//查看
      OtherHelp:'其他幫助',//其他帮助
      SubmitWorkOrder:'提交工單',//提交工单
      SubmitNow:'立即提交',//立即提交
      FeedbackHistory:'反饋歷史',//反馈历史
      GoToView:'進入查看',//进入查看
      TextOne:'聯繫客服',//联系客服
      TextTwo:'立即在線溝通',//立即在线沟通
    },
  },
  IncomeReflect: { //余额提现模块
    BalanceWithdrawal:'餘額提現',//余额提现
    WithdrawalRecord:'提現記錄',//提现记录
    PayeeManagement:'收款人管理',//收款人管理
    tabZero:{ //余额提现
      withdrawableAmount:'可提現金額',//可提现金额
      yuan:'元',//元
      Payee:'收款人',//收款人
      NewPayee:'新增收款人',//新增收款人
      PleaseSelect:'請選擇',//请选择
      CashWithdrawal:'提現金額',//提现金额
      TextOne:'請輸入提現金額',//请输入提现金额
      TextTwo:'每筆提現最低30元',//每笔提现最低30元
      phoneNumber:'手機號',//手机号
      PleasePhone:'請輸入手機號',//请输入手机号
      VerificationCode:'輸入驗證碼',//输入验证码
      TextThree:'請輸入驗證碼',//请输入验证码
      TextFour:'獲取驗證碼',//获取验证码
      Sent:'已發送',//已发送
      WithdrawCashNow:'立即提現',//立即提现
      TextFive:'您尚未進行手機綁定，提現成功後將默認綁定此手機號',//您尚未进行手机绑定，提现成功后将默认绑定此手机号
    },
    tabOne:{ //提现记录
      OrderNumber:'訂單號',//订单号
      Amount:'金額',//金额
      Status:'狀態',//状态
      Time:'時間',//时间
      TextOne:'提現記錄爲空~',//提现记录为空~
      awaitingProcessing:'待處理',//待处理
      Successful:'成功',//成功
      Fail :'失敗',//失败
      ViewCauses :'查看原因',//查看原因
    },
    tabTwo:{ //收款人管理
      NewPayee:'新增收款人',//新增收款人
      UserNickname:'用戶暱稱',//用户昵称
      AlipayAccount:'支付寶賬號',//支付宝账号
      IdNumber:'身份證號',//身份证号
      TextOne:'當月提現額度',//当月提现额度
      Controls:'操作',//操作
      Editor:'編輯',//编辑
      Delete:'刪除',//删除
      NoRecord:'暫無記錄',//暂无记录
    }
  },
  Account: { //账号管理模块
    TextOne:'諮詢客服了解更多',//咨询客服了解更多
    ContactCellPhone:'聯繫手機',//联系手机
    ContactEmail:'聯繫郵箱',//联系邮箱
    FEEMOOCertification:'飛貓達人認證',//飞猫达人认证
    FEEMOOCertificationTwo:'CloudMaster實名認證',//CloudMaster实名认证
    SubscriptionNumberOpening:'訂閱號開通',//订阅号开通
    CertifiedFEEMOOMaster:'已認證飛貓達人',//已认证飞猫达人
    CertifiedFEEMOOMasterTwo:'未認證CloudMaster',//未认证CloudMaster
    TextTwo:'已開通訂閱號',//已开通订阅号
    TextThree:'修改綁定手機',//修改绑定手机
    TextFour:'修改綁定郵箱',//修改绑定邮箱
    Accredited:'已認證',//已认证
    TextFive:'進入訂閱號',//进入订阅号
    BoundPhone:'綁定手機',//绑定手机
    BoundMailbox:'綁定郵箱',//绑定邮箱
    UncertifiedFEEMOO:'未認證飛貓達人',//未认证飞猫达人
    GetCertified:'去認證',//去认证
    SubscriptionNotAvailable:'未開通訂閱號',//未开通订阅号
    GoAndActivate:'去開通',//去开通

  },
  LevelTable: { //等级表格
    ClassInterest:'等級權益',//等级权益
    DownloadsYesterday:'昨日下載量',//昨日下载量
    TextOne:'大文件返利（元/萬次）',//大文件返利（元/万次）
    TextTwo:'小文件返利（元/萬次）',//小文件返利（元/万次）
    CouponCodeDiscount:'優惠碼折扣',//优惠码折扣
    PromoCodeRevenue:'優惠碼收益',//优惠码收益
  },
  TablePublic: { //表格组件模块
    UserNickname:'用戶暱稱',//用户昵称
    OpeningTime:'開通時間',//开通时间
    RebateAmount:'返利金額',//返利金额
    NoRecord:'暫無記錄',//暂无记录
    QRCode:'二維碼',//二维码
    InvitationCode:'邀請碼',//邀请码
    InvitationLink:'邀請鏈接',//邀请链接
  },
  MemRbCom: { //会员返利收益组件
    Today:'今日',//今日
    Yesterday:'昨日',//昨日
    CurrentEarnings:'本月收益',//本月收益
    yuan:'元',//元
  },
  userNew: { // 信息悬浮框模块
    All: '全部', // 全部
    SystemNotification: '系統通知', // 系统通知
    OfficialNews: '官方消息', // 官方消息
    ReadAll: '全部已讀', // 全部已读
    Nonews: '暫無消息', // 暂无消息
  },
}
